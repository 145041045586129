<template>
  <div class="page" id="reorganize">
    <div class="bg-white">
      <el-button size="small" @click="returnPage()">返回</el-button>
      <el-form size="small" class="query-form dynamicForm" ref="inputForm" :model="inputForm"
               @keyup.enter.native="getDataList(1,1,1)"
               :rules="rules" label-width="140px">
        <el-form-item label="藏品名称" prop="collectionName">
          <el-input v-model.trim="inputForm.collectionName"
                    placeholder="请输入藏品名称(限50字)"
                    maxlength="50" clearable></el-input>
        </el-form-item>
        <el-form-item label="藏品编号" prop="helpNum">
          <el-input v-model.trim="inputForm.helpNum"
                    placeholder="请输入藏品编号(限50字)"
                    maxlength="50"
                    clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="完残程度" prop="integrity">
          <el-select v-model="inputForm.integrity"
                     placeholder="请选择完残程度"
                     style="width: 100%"
                     clearable>
            <el-option
                v-for="item in integrityList"
                :key="item.id"
                :label="item.levelName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="完残情况" prop="integrityInfo">
          <el-input v-model="inputForm.integrityInfo"
                    placeholder="请输入完残情况(限50字)"
                    maxlength="50" clearable></el-input>
        </el-form-item>
        <el-form-item label-width="0" :prop="item.code"
                      v-for="(item, index) in config"
                      :key="index" v-if="item.dataWhere == 0">
          <div class="flex_b_c metadata">
            <el-tooltip :disabled="item.overflow" class="item" effect="dark" :content="item.basicName"
                        placement="top">
              <div class="omit text_right metadataName" ref="configName">{{ item.basicName }}</div>
            </el-tooltip>
            <div class="metadataCont">
              <!--字符-->
              <el-input v-model.trim="inputForm[item.code]" :maxlength="item.maxLength"
                        :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                        clearable
                        v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
              <!--数值、浮点数-->
              <el-input v-model.trim="inputForm[item.code]"
                        :maxlength="item.archivesBasicTypeCode == 'integer'? '8':'11'"
                        @input="getNumType(item)"
                        :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                        clearable
                        v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
              <el-select class="w100i" v-model="inputForm[item.code]"
                         :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" collapse-tags
                         :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                         clearable
                         v-if="item.archivesBasicMultiselectList.length">
                <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                           :label="v.archivesBasicValue"
                           :value="v.archivesBasicValue"></el-option>
              </el-select>
              <!--时间-->
              <el-date-picker class="w100i"
                              v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                              format="yyyy-MM-dd HH:mm:ss"
                              type="datetime" clearable placeholder="请选择"
                              v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
              </el-date-picker>
              <el-date-picker class="w100i"
                              v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                              format="yyyy-MM-dd"
                              type="date" clearable placeholder="请选择"
                              v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
              </el-date-picker>
              <el-date-picker class="w100i"
                              v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                              format="yyyyMMdd"
                              type="date" clearable placeholder="请选择"
                              v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="flex_b_c">
        <div>
          <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1,1)">查询
          </el-button>
          <el-button icon="el-icon-refresh-right" size="small" @click="resetting()">重置</el-button>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-search" size="small" @click="advancedSearch()">
            高级检索
          </el-button>
          <el-button size="small" @click="exportData(1)">
            <i class="icon-piliangdaochu iconfont buIcon"/>
            批量导出
          </el-button>
        </div>
      </div>
    </div>

    <div class="bg-white" style="margin-top: 10px">
      <el-table
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          height="calc(100vh - 350px)"
          class="table"
          :row-key="'id'"
          @sort-change="sortChange"
          @selection-change="selectionChangeHandle"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="processState" label="审核状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("process_state", scope.row.processState, '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="图标" width="120px">
          <template slot-scope="scope">
            <div class="flex_a_c">
              <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
              <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.picMasterViewUrl"
                  :fit="'cover'"
                  :preview-src-list="[scope.row.picMasterViewUrl]"
              >
                <div slot="error" class="image-slot">
                  <el-image
                      style="width: 36px; height: 36px"
                      :src="require('@/assets/img/default.png')"
                      :fit="'cover'">
                  </el-image>
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="collectionName" label="藏品名称"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="helpNum" sortable min-width="120px" label="辅助账编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="generalNum" sortable min-width="120px" label="总账编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="integrityInfo" label="完残情况" show-overflow-tooltip></el-table-column>
        <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
        <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                         :prop="item.code"
                         :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                         :label="item.basicName" v-if="item.dataSelect == 0" min-width="120"
                         show-overflow-tooltip>
          <template slot="header" slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                        placement="top-start">
              <span class="omit">{{ item.forName ? item.forName : item.basicName }}</span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <span
                v-if="item.archivesBasicTypeCode == 'date'">{{
                metadataDateFormatValue(item, scope.row.archivesData[item.code])
              }}</span>
            <span v-else>{{ scope.row.archivesData[item.code] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建者" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" sortable label="创建时间" width="150px">
          <template slot-scope="scope">
            {{ scope.row.createDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="updateUserName" label="最后更新人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.updateDate | formatDate }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="addData(1, scope.row,scope.$index)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    <ExportData ref="exportData" @downLoad="getDataList('',1)"></ExportData>
  </div>
</template>

<script>
import AdvancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import ExportData from "@/views/modules/record/collect/exportData.vue";

export default {
  name: "ledgerList",
  components: {ExportData, AdvancedSearch},
  data() {
    return {
      archivesBasicDataId: '',
      inputForm: {},
      rules: {},
      config: [],
      advancedList: [],
      searchForm: {
        whereList: [],
        sortArchivesDataElasticsearch: [],
      },

      dataList: [],
      loading: false,
      dataListSelect: [],
      inputFormCopy: '',
      pageNo: 1,
      pageNo2: '',
      pageSize: 10,
      total: 0,
      searchRecord: {},
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
    }
  },

  mounted() {
    this.archivesBasicDataId = this.$route.query.archivesBasicDataId
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.archivesBasicDataId = listSearch.archivesBasicDataId
      this.advancedList = listSearch.advancedList
      this.inputFormCopy = listSearch.inputForm
      this.searchForm.whereList = listSearch.advancedList
      this.pageNo2 = listSearch.pageNo
      this.pageSize = listSearch.pageSize
      sessionStorage.removeItem('listSearch')
    }
    this.getSearchFrom()
  },

  methods: {
    //获取元数据
    getSearchFrom() {
      this.$axios(this.api.collection.basicdataGetById + '/' + this.archivesBasicDataId).then(data => {
        if (data.status) {
          this.inputForm = {
            helpNum: '',
            collectionName: '',
            integrity: '',
            integrityInfo: '',
          }
          this.rules = {}
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
          if (this.inputFormCopy) {
            // Object.keys(this.inputForm).forEach(key => {
            //   this.inputForm[key] = this.inputFormCopy[key]
            // })
            this.inputForm = this.inputFormCopy
            this.inputFormCopy = ''
          }
          this.$nextTick(() => {
            this.textExceeds(this.config, 'configName')
          })
          this.getDataList('', 1, 1)
        }
      })
    },

    //判断文字是否超出范围
    textExceeds(list, refName) {
      let configName = this.$refs[refName]
      if (!list || !list.length) return
      list.forEach((item, index) => {
        if (configName[index].scrollWidth > configName[index].clientWidth) {
          this.$set(item, 'overflow', false)
        } else {
          this.$set(item, 'overflow', true)
        }
      })
    },

    //获取列表数据
    getDataList(type, dividePage, archivesData) {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          if (type == 1) {
            this.pageNo = 1
          }
          if (dividePage == 1) {
            this.$refs.multipleTable.clearSelection()
          }
          if (archivesData == 1) {
            this.searchForm.sortArchivesDataElasticsearch = []
            this.$refs.multipleTable.clearSort()
          }
          this.loading = true
          let keys = Object.keys(this.inputForm)
          let values = Object.values(this.inputForm)
          let searchForm = JSON.parse(JSON.stringify(this.searchForm))
          keys.map((key, index) => {
            this.config.map(item => {
              if (item.code == key && values[index] != '' && values[index] != null) {
                if (item.archivesBasicTypeCode == 'text') {
                  // 多选遍历多选的值
                  if (Array.isArray(values[index])) {
                    values[index].map(v => {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: v
                      })
                    })
                  } else {
                    if (item.archivesBasicMultiselectList.length) {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    } else {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    }
                  }
                }
                if (item.archivesBasicTypeCode == 'integer') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
                if (item.archivesBasicTypeCode == 'date') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: ">=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "<=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
                if (item.archivesBasicTypeCode == "double") {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
              }
            })
          })
          let fieldOrList = [
            {
              "archivesBasicTypeMethodCode": "=",
              "key": "entryState",
              "theKey": "",
              "value": "1"
            },
            {
              "archivesBasicTypeMethodCode": "=",
              "key": "entryState",
              "theKey": "",
              "value": "2"
            },
          ]
          let fieldList = []
          fieldList.push(
              {
                "archivesBasicTypeMethodCode": "=",
                "key": "processState",
                "theKey": "",
                "value": "3"
              }
          )
          let fieldWhereList = []

          if (this.inputForm.collectionName) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "%",
                  key: 'collectionName',
                  archivesBasicTypeCode: 'text',
                  value: this.inputForm.collectionName
                },
            )
          }
          if (this.inputForm.integrity) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrity',
                  value: this.inputForm.integrity
                },
            )
          }
          if (this.inputForm.integrityInfo) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrityInfo',
                  value: this.inputForm.integrityInfo
                },
            )
          }
          this.searchRecord = {
            ...searchForm,
            fieldOrList: fieldOrList,
            fieldList: fieldList,
            fieldWhereList: fieldWhereList,
            current: this.pageNo2 || this.pageNo,
            size: this.pageSize,
            number: this.inputForm.helpNum,
            archivesBasicDataId: [this.archivesBasicDataId],
          },
              this.$axios(this.api.collection.queryElasticsearchTemplatePage, this.searchRecord, 'post').then((res) => {
                if (res.status) {
                  this.dataList = res.data.records
                  this.$nextTick(() => {
                    this.$refs.multipleTable.doLayout()
                  })
                  this.total = parseInt(res.data.total)
                  if (this.pageNo2) {
                    this.pageNo = this.pageNo2
                    this.pageNo2 = ''
                  }
                  if (this.dataList.length == 0 && this.pageNo > 1) {
                    this.pageNo--
                    this.getDataList()
                  }
                } else {
                  this.$message.error('查询失败');
                }
                this.loading = false
              })
        }
      })
    },

    // 列表排序
    sortChange(column) {
      if (column.order){
        if (column.prop == 'createDate' || column.prop == 'updateDate') {
          this.searchForm.sortArchivesDataElasticsearch = [
            {
              archivesBasicTypeCode: 'date',
              isSys: 1,
              key: column.prop,
              sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
            }
          ]
        } else if (column.prop == 'helpNum' || column.prop == 'generalNum') {
          this.searchForm.sortArchivesDataElasticsearch = [
            {
              archivesBasicTypeCode: 'text',
              isSys: 1,
              key: column.prop,
              sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
            }
          ]
        } else {
          let sortItem = this.config.filter(item => {
            return item.code == column.prop
          })
          this.searchForm.sortArchivesDataElasticsearch = [
            {
              archivesBasicTypeCode: sortItem[0].archivesBasicTypeCode,
              isSys: 0,
              key: 'archivesData.' + column.prop,
              sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
            }
          ]
        }
      }else {
        this.searchForm.sortArchivesDataElasticsearch = []
      }
      this.getDataList('', 1)
    },

    //导出 type 1批量导出 2全部导出
    exportData(type) {
      let ids = []
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      ids = this.dataListSelect.map(item => {
        if (item) {
          return {
            id: item.id,
            archivesBasicDataId: item.archivesBasicDataId
          }
        }
      })
      this.$refs.exportData.init(ids, this.config, this.archivesBasicDataId, '藏品数据', 1)
    },

    returnPage() {
      this.$router.push({path: '/collection/accounts/ledger', query: {fanhui: true}})
    },

    //1详情
    addData(num, row, index) {
      let listSearch = {
        archivesBasicDataId: this.archivesBasicDataId,
        advancedList: this.advancedList,
        inputForm: this.inputForm,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
      this.$set(this.searchRecord, 'viewCurrent', (this.pageNo - 1) * this.pageSize + index + 1)
      sessionStorage.setItem('registrationSearch', JSON.stringify(this.searchRecord))
      this.$router.push({
        path: '/collection/accounts/addCollection',
        query: {butType: num, id: row.id, archivesBasicDataId: row.archivesBasicDataId}
      })
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.searchForm.whereList = []
      this.advancedList = []
      this.getDataList(1, 1, 1)
    },

    // 高级检索
    advancedSearch() {
      this.$refs.advancedSearch.init(this.config, this.advancedList)
    },

    // 获取高级检索条件
    getSrhCondition(data) {
      if (data) {
        this.advancedList = data.advancedList
        this.searchForm.whereList = data.whereList
        this.getDataList(1, 1, 1)
      }
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}
</style>
